import React from "react";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MobileFrame, { FRAME_INNER_WIDTH } from "../../../components/MobileFrame";
import { useLoginContext } from "../../../providers/LoginContextProvider";

type CouponContentForPreview = {
  image_url: string;
  benefit: string;
  body: string;
  terms: string;
  has_usage_limit: "true" | "false";
  expiration: string | null;
  expiration_day: number | null;
  expiration_time: number | null;
};
type Props = {
  couponContent: CouponContentForPreview;
};

const CouponPreview = ({
  couponContent
}: Props): JSX.Element => {
  const { currentStore, childStores } = useLoginContext();
  const availableStores = currentStore.store_type === "child" ? [currentStore] : childStores.child_stores.map((s) => s.store);

  return (
    <StyledStickyContainer>
      <MobileFrame
        footer={
          <UseButtonContainer>
            <UseButton>
              利用する
            </UseButton>
          </UseButtonContainer>
        }
      >
        <Box p={1} position="relative">
          <StyledHeader>
            <Box fontSize={16}>クーポン</Box>
            <i className="ri-close-line" />
          </StyledHeader>

          {couponContent.image_url && (
            <Box mb={3} width={MOBILE_CONTAINER_WIDTH}>
              <StyledCouponContentImage src={couponContent.image_url} alt="添付画像" />
            </Box>
          )}

          <StyledTitle empty={!Boolean(couponContent.benefit)}>
            {couponContent.benefit || "クーポンのタイトル"}
          </StyledTitle>

          <Box display="flex" alignItems="center" mb={2}>
            {currentStore.app_icon_url && (
              <StyledStoreIcon src={currentStore.app_icon_url} alt="クーポン配信元店舗" />
            )}

            <Box fontSize={12} color="#777">
              <Box fontWeight={700}>{currentStore.name}</Box>
              <div>最新情報を見る</div>
            </Box>
          </Box>

          <StyledBody empty={!Boolean(couponContent.body)} mb={1}>
            {couponContent.body || "クーポンのサービス内容"}
          </StyledBody>

          <Divider />

          <Box my={1}>
            <StyledSectionTitle>
              利用条件
            </StyledSectionTitle>

            <StyledBody empty={!Boolean(couponContent.terms)} mt={1}>
              {couponContent.terms || "クーポンの利用条件"}
            </StyledBody>
          </Box>

          <Divider />

          <StyledSectionContainer>
            <StyledSectionTitle>
              利用可能回数
            </StyledSectionTitle>

            <StyledBody>
              {couponContent.has_usage_limit === "true" ? "1回" : "制限なし"}
            </StyledBody>
          </StyledSectionContainer>

          <Divider />

          <StyledSectionContainer>
            <StyledSectionTitle>
              有効期限
            </StyledSectionTitle>

            <StyledBody>
              {
                couponContent.expiration ||
                moment()
                  .add(couponContent.expiration_day, "days")
                  .set("hour", couponContent.expiration_time ?? 0)
                  .set("minute", 0)
                  .format("YYYY/MM/DD HH:mm")
              }
            </StyledBody>
          </StyledSectionContainer>

          <Divider />

          <StyledSectionContainer>
            <StyledSectionTitle>
              利用可能店舗
            </StyledSectionTitle>

            {availableStores.length > 1 && (
              <StyledBody>
                {availableStores.length}店舗
              </StyledBody>
            )}
          </StyledSectionContainer>

          <Box overflow="scroll" width={MOBILE_CONTAINER_WIDTH}>
            <Box display="flex" gap={1}>
              {availableStores.map((store) => (
                <StyledStoreContainer>
                  {store.app_icon_url && (
                    <StyledAvailableStoreIcon src={store.app_icon_url} alt={store.name} />
                  )}

                  <Box fontSize={12}>
                    {store.name}
                  </Box>

                  {store.appeal && (
                    <StyledAppeal>
                      {store.appeal}
                    </StyledAppeal>
                  )}
                </StyledStoreContainer>
              ))}
            </Box>
          </Box>
        </Box>
      </MobileFrame>
    </StyledStickyContainer>
  );
};

const MOBILE_CONTAINER_WIDTH = FRAME_INNER_WIDTH - (8 * 2);
const STORE_CONTAINER_WIDTH = FRAME_INNER_WIDTH / 2 - 32;

const StyledStickyContainer = styled("div")({
  position: "sticky",
  top: 16
});

const StyledHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16
});

const StyledTitle = styled("div")<{ empty: boolean }>(({ empty }) => ({
  fontSize: 18,
  fontWeight: 700,
  marginBottom: 8,
  ...(empty ? { color: "#aaa" } : {})
}));

const StyledStoreIcon = styled("img")({
  width: 32,
  height: 32,
  borderRadius: 32,
  marginRight: 4
});

const StyledCouponContentImage = styled("img")({
  width: MOBILE_CONTAINER_WIDTH,
  borderRadius: 8,
  boxSizing: "border-box",
  objectFit: "contain"
});

const StyledBody = styled(Box)<{ empty?: boolean }>(({ empty = false }) => ({
  fontSize: 14,
  whiteSpace: "pre-wrap",
  ...(empty ? { color: "#aaa" } : {})
}));

const StyledSectionContainer = styled("div")(({ theme }) => ({
  margin: theme.spacing(2, 0),
  display: "flex",
  gap: theme.spacing(1)
}));
const StyledSectionTitle = styled("div")({
  width: 100,
  color: "gray",
  fontSize: 14
});

const StyledStoreContainer = styled("div")(({ theme }) => ({
  width: STORE_CONTAINER_WIDTH,
  borderRadius: 4,
  border: "solid 1px #eee",
  padding: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1)
}));
const StyledAvailableStoreIcon = styled("img")({
  width: STORE_CONTAINER_WIDTH - 16,
  height: STORE_CONTAINER_WIDTH - 16,
  borderRadius: 4
});
const StyledAppeal = styled("div")(({ theme }) => ({
  fontSize: 12,
  padding: theme.spacing(0.5),
  backgroundColor: "#f4f4f4",
  width: STORE_CONTAINER_WIDTH - 16,
  overflow: "hidden",
  display: "-webkit-box",
  textOverflow: "ellipsis",
  "-webkit-box-orient": "vertical",
  "-webkit-line-clamp": "3",
  maxHeight: 58
}));

const UseButtonContainer = styled("div")(({ theme }) => ({
  width: "100%",
  backgroundColor: "white",
  display: "flex",
  justifyContent: "center",
  padding: theme.spacing(1),
  borderTop: "solid 1px #eee"
}));
const UseButton = styled("div")(({ theme }) => ({
  backgroundColor: "#FE6767",
  borderRadius: 4,
  color: "white",
  padding: theme.spacing(0.5, 0),
  fontSize: 14,
  fontWeight: 700,
  textAlign: "center",
  width: "100%"
}));

export default CouponPreview;
