import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Prompt, useHistory } from "react-router";
import { AxiosError } from "axios";
import { Grid, Row, Col, FormGroup, Form, HelpBlock } from "react-bootstrap";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Backdrop, CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import "flatpickr/dist/themes/material_blue.css";
import { cloneDeep } from "lodash";
import { useLoginContext } from "../../../providers/LoginContextProvider";
import { useNotification } from "../../../providers/NotificationProvider";
import SelectorForm from "../../../components/FormInputs/SelectorForm";
import { Card } from "../../../components/Card/Card";
import FormInputs from "../../../components/FormInputs/FormInputs";
import ImageForm from "../../../components/FormInputs/ImageForm";
import FormLabel from "../../../components/FormInputs/FormLabel";
import Checkbox from "../../../components/CustomCheckbox/CustomCheckbox";
import ChangeIndicator from "../../../components/ChangeIndicator";
import { getObjectDiff } from "../../../lib/general";
import { DEFAULTIMAGE } from "../../../lib/defaultImage";
import { compareValidateDate, validateDate } from "../../../lib/validation";
import { TargetUserConditionResource } from "../../../types/resource/targetUserCondition.d";
import CouponContentEntityContainer, {
  CouponContent,
  CouponStatusType,
  CouponStatus,
  ServiceTypes,
  AutoCouponContentStatusToJpObj,
} from "../../../containers/entities/CouponContentEntityContainer";
import AutoCouponContentEntityContainer from "../../../containers/entities/AutoCouponContentEntityContainer";
import AutoPublishFormCard from "../../../components/Card/AutoPublishFormCard";
import DiscountFormCard from "./DiscountFormCard";
import QuestionnaireFormCard from "./QuestionnaireFormCard";
import TargetUserForm from "../../../components/TargetUser";
import { validateTargetUserCondition } from "../../../components/TargetUser/utils";
import {
  useTargetUserConditionResourceConverter,
  useTargetUserConditionEffect,
  DefaultTargetUserCondition
} from "../../../components/TargetUser/hooks";
import { useApi, useBooleanState, usePcSizeFlag } from "../../../lib/hooks";
import PresentCouponDistributionSettingsCard from "./PresentCouponDistributionSettingsCard";
import AutoCouponFormActiveSettingCard from "./AutoCouponFormActiveSettingCard";
import CouponQuestionnaireForm from "./CouponQuestionnaireForm";
import FormInput from "../../../components/FormInputs/FormInput";
import { FormChangedAlertModal, Alert } from "../../../components/Modal/FormChangedAlertModal";
import CouponPreview from "./CouponPreview";

type Props = {
  defaultCouponContent?: any;
  defaultTargetUserCondition?: TargetUserConditionResource,
  defaultQuestionnaireId?: number;
  editMode?: boolean;
  isAuto: boolean;
  style?: React.CSSProperties;
};

const CouponContentForm = ({
  defaultCouponContent,
  defaultTargetUserCondition,
  defaultQuestionnaireId,
  editMode = false,
  isAuto,
  style = {}
}: Props): JSX.Element => {
  const { hasFunction, lineChannel, currentStore } = useLoginContext();
  const history = useHistory();
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const isPcOrTablet = usePcSizeFlag();
  const title = isAuto ? "自動配信クーポン" : "通常配信クーポン";
  const coupon = defaultCouponContent;
  const defaultShowDiscountFormCard = Boolean(coupon?.service_type || coupon?.service_value);
  const [isCouponDeleteModalOpen, openCouponDeleteModal, closeCouponDeleteModal] = useBooleanState(false);
  const [isShowDiscountFormCard, setIsShowDiscountFormCard] = useState(defaultShowDiscountFormCard);
  const [repeatType, setRepeatType] = useState<"date" | "week">(coupon?.publish_weeks?.length ? "week" : "date");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isBackdropLoading, setIsBackdropLoading] = useState(false);
  const convertToResource = useTargetUserConditionResourceConverter();
  const api = useApi();

  const couponContentEntityContainer = CouponContentEntityContainer.useContainer();
  const {
    createPresentCouponContent,
    updatePresentCouponContent,
    publishPresentCouponContent,
    serviceTypeToJp,
    statusToJp,
    statusLabelBackgroundColor,
  } = couponContentEntityContainer.logic;

  const autoCouponContentEntityContainer = AutoCouponContentEntityContainer.useContainer();
  const { categories } = autoCouponContentEntityContainer.constant;
  const {
    createAutoCouponContent,
    updateAutoCouponContent,
    categoryToJp,
  } = autoCouponContentEntityContainer.logic;

  const DefaultCouponContent = {
    status: CouponStatus.Unpublished,
    body: "",
    terms: "他のクーポンとの併用はできません。\nクーポンのみでのお店のご利用はお控えください。",
    important_notes: "・クーポン利用の際はスタッフにこの画面を提示して下さい\n・一度利用すると操作の取り消しができません",
    image_url: DEFAULTIMAGE,
    do_push: true,
    send_line_message: false,
    publish_at: null,
    expiration: null,
    service_type: null,
    service_value: null,
    enabled_target_user_condition: false,
    questionnaire_url: null,
    customer_unit_price: currentStore.customer_unit_price || 0,
    memo: ""
  };

  const defaultValues = {
    coupon: {
      questionnaire_id: defaultQuestionnaireId || null,
      benefit: Boolean(defaultQuestionnaireId) ? "アンケートに回答してクーポンを獲得！" : "",
      ...(coupon || DefaultCouponContent)
    },
    target_user_condition: DefaultTargetUserCondition
  };
  const methods = useForm({ defaultValues });

  const {
    register,
    unregister,
    handleSubmit,
    errors,
    getValues,
    watch,
    control,
    setValue,
    setError,
    clearErrors,
    formState,
    reset,
    trigger,
  } = methods;

  useTargetUserConditionEffect(
    defaultTargetUserCondition || null,
    (targetUserCondition) => setValue("target_user_condition", targetUserCondition)
  );

  const { isDirty, dirtyFields } = formState;

  const watchCategory = watch("coupon.category");
  const watchPublishDates: number[] = watch("coupon.publish_dates");
  const watchPublishWeeks: number[] = watch("coupon.publish_weeks");
  const watchServiceType = watch("coupon.service_type");
  const watchDaysLater: number = watch("coupon.days_later");
  const watchLimit = watch("coupon.has_usage_limit") === "true";
  const watchCoupon = watch("coupon");

  const processFormData = async (data): Promise<CouponContent> => {
    const processedData = cloneDeep(data.coupon);

    if (coupon === null || coupon?.image_url !== processedData.image_url) {
      processedData.image = processedData.image_url;
    }

    if (coupon && repeatType === "date") {
      processedData.publish_weeks = [];
    } else if (coupon && repeatType === "week") {
      processedData.publish_dates = [];
    }

    if (coupon && !isShowDiscountFormCard) {
      processedData.service_type = null;
      processedData.service_value = null;
    }

    if (coupon?.has_usage_limit === JSON.parse(processedData?.has_usage_limit)) {
      delete processedData.has_usage_limit;
    }

    processedData.target_user_condition = await convertToResource(data.target_user_condition);

    // 編集時に配信設定カード内のみで変更可
    if (editMode) {
      delete processedData.status;
      delete processedData.publish_at;
      delete processedData.expiration;
    }

    return processedData;
  };

  const onClickCreateButton = async (data) => {
    try {
      if (isAuto) {
        await createAutoCouponContent(data);
      } else {
        await createPresentCouponContent(data);
      }
      showSuccessNotification(`${title}を作成しました。`);
      reset();
      history.goBack();
    } catch (error) {
      showErrorNotification((error as AxiosError).message);
    }
  };

  const onClickUpdateButton = async (data): Promise<void> => {
    const params = getObjectDiff(coupon, data);

    try {
      if (isAuto) {
        await updateAutoCouponContent({ id: coupon.id, params });
      } else {
        await updatePresentCouponContent({ id: coupon.id, params });
      }
      showSuccessNotification(`${title}を更新しました。`);
      reset();
      history.goBack();
    } catch (error) {
      showErrorNotification((error as AxiosError).message);
    }
  };

  const handleClickDeleteButton = () => {
    if (coupon.published_number === 0) {
      if (!window.confirm("このクーポンを削除しますか？")) return;
      deleteCouponContent();
    } else {
      openCouponDeleteModal();
    }
  };

  const deleteCouponContent = async (): Promise<void> => {
    const res = await api.api.delete(`/coupon_contents/${coupon?.id}`);
    if (!res) return;

    showSuccessNotification("クーポンを削除しました。");
    reset();
    history.goBack();
  };

  const changeStatusToPreviewOrUnpublished = async (
    status: typeof CouponStatus.Preview | typeof CouponStatus.Unpublished
  ): Promise<void> => {
    const isDirtyExceptStatusAndPublishAtAndExpiration = !!Object.keys(
      dirtyFields.coupon || {}
    ).filter((key) => key !== "status" && key !== "publish_at" && key !== "expiration").length;
    const isSave = isDirtyExceptStatusAndPublishAtAndExpiration
      ? window.confirm("行った変更が保存されていない可能性があります。変更内容を保存しますか？")
      : false;
    if (isSave && !(await trigger())) {
      showErrorNotification("値が正しく入力されていません。");
      return;
    }
    const isUpdateStatus = window.confirm(
      status === CouponStatus.Preview
        ? "このクーポンはスタッフ権限を有効にしている全ての端末へ表示されます。表示してよろしいですか？"
        : `下書きに変更しますか？`
    );

    if (!isSave && !isUpdateStatus) {
      return;
    }

    const data = getValues();
    const params = isSave
      ? {
          ...getObjectDiff(coupon, await processFormData(data)),
          status: isUpdateStatus ? status : undefined,
          publish_at: null,
          expiration: null,
        }
      : {
          status: isUpdateStatus ? status : undefined,
          publish_at: null,
          expiration: null,
        };

    await updatePresentCouponContent({ id: coupon.id, params })
      .then(() => {
        showSuccessNotification(
          status === CouponStatus.Preview
            ? "クーポンをアプリでプレビュー表示しました。"
            : "クーポンを下書きにしました。"
        );
        reset();
        history.goBack();
      })
      .catch((error) => {
        showErrorNotification((error as AxiosError).message);
      });
  };

  const publishPresentCouponContentByNow = async (): Promise<void> => {
    const expiration = getValues("coupon.expiration");
    const expirationValidationResult = validateDate(expiration);
    if (!expiration) {
      showErrorNotification("有効期限を設定してください");
      return;
    }
    if (!expirationValidationResult.validation) {
      showErrorNotification(expirationValidationResult.message);
      return;
    }

    const isDirtyExceptStatusAndPublishAtAndExpiration = !!Object.keys(
      dirtyFields.coupon || {}
    ).filter((key) => key !== "status" && key !== "publish_at" && key !== "expiration").length;
    const isSave = isDirtyExceptStatusAndPublishAtAndExpiration
      ? window.confirm("行った変更が保存されていない可能性があります。変更内容を保存しますか？")
      : false;
    if (isSave && !(await trigger())) {
      showErrorNotification("値が正しく入力されていません。");
      return;
    }

    const isPublish = window.confirm("配信しますか？");
    if (!isSave && !isPublish) {
      return;
    }

    try {
      if (isSave) {
        const data = getValues();
        const params = {
          ...getObjectDiff(coupon, await processFormData(data)),
          publish_at: isPublish ? null : undefined,
          expiration: isPublish ? expiration : undefined,
        };

        await updatePresentCouponContent({ id: coupon.id, params });
      }
      if (isPublish) {
        await publishPresentCouponContent({ id: coupon.id, params: { expiration } });
      }

      reset();
      history.goBack();
      showSuccessNotification("クーポンを配信しました。");
    } catch (error) {
      showErrorNotification((error as AxiosError).message);
    }
  };

  const publishPresentCouponContentByReservation = async (): Promise<void> => {
    const publishAt = getValues("coupon.publish_at");
    const expiration = getValues("coupon.expiration");
    const publishAtValidationResult = validateDate(publishAt);
    const expirationValidationResult = validateDate(expiration);
    const compareDateValidationResult = compareValidateDate(publishAt, expiration);

    if (!publishAtValidationResult.validation || !expirationValidationResult.validation) {
      showErrorNotification(
        publishAtValidationResult.message || expirationValidationResult.message
      );
      return;
    }
    if (!compareDateValidationResult.validation) {
      showErrorNotification("配信予定日よりも過去の有効期限を設定することはできません。");
      return;
    }

    const isDirtyExceptStatusAndPublishAtAndExpiration = !!Object.keys(
      dirtyFields.coupon || {}
    ).filter((key) => key !== "status" && key !== "publish_at" && key !== "expiration").length;
    const isSave = isDirtyExceptStatusAndPublishAtAndExpiration
      ? window.confirm("行った変更が保存されていない可能性があります。変更内容を保存しますか？")
      : false;
    if (isSave && !(await trigger())) {
      showErrorNotification("値が正しく入力されていません。");
      return;
    }

    const isPublish = window.confirm("配信予約しますか？");

    if (!isSave && !isPublish) {
      return;
    }

    const data = getValues();
    const params = isSave
      ? {
          ...getObjectDiff(coupon, await processFormData(data)),
          publish_at: isPublish ? publishAt : undefined,
          expiration: isPublish ? expiration : undefined,
          status: isPublish ? CouponStatus.Reservation : undefined,
        }
      : {
          publish_at: isPublish ? publishAt : undefined,
          expiration: isPublish ? expiration : undefined,
          status: isPublish ? CouponStatus.Reservation : undefined,
        };

    try {
      await updatePresentCouponContent({ id: coupon.id, params });
      showSuccessNotification("配信予約しました。");
      reset();
      history.goBack();
    } catch (error) {
      showErrorNotification((error as AxiosError).message);
    }
  };

  const onClickPresentCouponContentPublishButton = async (
    newStatus: CouponStatusType
  ): Promise<void> => {
    try {
      setIsBackdropLoading((prevIsBackdropLoading) => !prevIsBackdropLoading);
      switch (newStatus) {
        case CouponStatus.Preview:
          await changeStatusToPreviewOrUnpublished(newStatus);
          break;
        case CouponStatus.Publish:
          await publishPresentCouponContentByNow();
          break;
        case CouponStatus.Reservation:
          await publishPresentCouponContentByReservation();
          break;
        case CouponStatus.Unpublished:
          changeStatusToPreviewOrUnpublished(newStatus);
          break;
        default:
          break;
      }
    } finally {
      setIsBackdropLoading(false);
    }
  };

  const onClickAutoCouponContentPublishButton = async (newStatus): Promise<void> => {
    const isDirtyExceptStatus = !!Object.keys(dirtyFields.coupon || {}).filter(
      (key) => key !== "status"
    ).length;

    const isSave = isDirtyExceptStatus
      ? window.confirm("行った変更が保存されていない可能性があります。変更内容を保存しますか？")
      : false;
    if (isSave && !(await trigger())) {
      showErrorNotification("値が正しく入力されていません。");
      return;
    }

    const isPublish = window.confirm(`${AutoCouponContentStatusToJpObj[newStatus]}にしますか？`);

    if (!isSave && !isPublish) {
      return;
    }

    const data = getValues();
    const params = isSave
      ? {
          ...getObjectDiff(coupon, await processFormData(data)),
          status: isPublish ? newStatus : undefined,
        }
      : {
          status: isPublish ? newStatus : undefined,
        };

    try {
      await updateAutoCouponContent({ id: coupon.id, params });
      reset();
      history.goBack();
      showSuccessNotification(`${AutoCouponContentStatusToJpObj[newStatus]}にしました。`);
    } catch (error) {
      showErrorNotification((error as AxiosError).message);
    }
  };

  const onSubmit = async (data) => {
    const formData = await processFormData(data);
    setIsButtonLoading(true);
    if (editMode) {
      onClickUpdateButton(formData).finally(() => setIsButtonLoading(false));
    } else {
      onClickCreateButton(formData).finally(() => setIsButtonLoading(false));
    }
  };

  const onError = () => showErrorNotification("値が正しく入力されていません。");

  useEffect(() => {
    register("coupon.status");
    register("coupon.questionnaire_id");
    register("target_user_condition", { validate: validateTargetUserCondition });

    return () => {
      unregister("coupon.status");
      unregister("coupon.questionnaire_id");
      unregister("target_user_condition");
    };
  }, [register, unregister]);

  return (
    <FormProvider {...methods}>
      <Grid fluid className="content" style={style}>
        <div style={{ display: "flex" }}>
          <Form onSubmit={handleSubmit(onSubmit, onError)} style={{ flex: 1 }}>
            {editMode && !isAuto && (
              <PresentCouponDistributionSettingsCard
                editMode={editMode}
                coupon={coupon}
                onClickPresentCouponContentPublishButton={onClickPresentCouponContentPublishButton}
                statusToJp={statusToJp}
                statusLabelBackgroundColor={statusLabelBackgroundColor}
              />
            )}

            {editMode && isAuto && (
              <Row>
                <Col md={12}>
                  <AutoCouponFormActiveSettingCard
                    coupon={coupon}
                    onClickAutoCouponContentPublishButton={onClickAutoCouponContentPublishButton}
                    editMode={editMode}
                  />
                </Col>
              </Row>
            )}

            <Row>
              <Col md={12}>
                <Card
                  title={editMode ? `${title}編集` : `${title}作成`}
                  content={
                    <>
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <StyledCheckBoxContainer>
                              <Checkbox
                                number={1}
                                inline
                                label="プッシュ通知"
                                name="coupon.do_push"
                                disabled={!isAuto && coupon?.status === CouponStatus.Publish}
                                inputRef={register}
                              />
                              <Checkbox
                                style={{ display: lineChannel ? "block" : "none" }}
                                number={2}
                                inline
                                label="LINE公式アカウント"
                                name="coupon.send_line_message"
                                disabled={!isAuto && coupon?.status === CouponStatus.Publish}
                                inputRef={register}
                              />

                              {editMode && Boolean(dirtyFields.coupon?.do_push) && (
                                <ChangeIndicator />
                              )}
                            </StyledCheckBoxContainer>
                          </FormGroup>
                        </Col>
                      </Row>

                      <FormInputs
                        properties={[
                          {
                            name: "coupon.benefit",
                            label: "タイトル",
                            ncol: "col-md-9",
                            type: "text",
                            bsClass: "form-control",
                            inputRef: register({
                              required: "タイトルを入力してください。",
                            }),
                            validationMessage: errors?.coupon?.benefit?.message,
                            showChangeIndicator: editMode && Boolean(dirtyFields.coupon?.benefit)
                          },
                          {
                            name: "coupon.body",
                            label: "サービス内容",
                            ncol: "col-md-12",
                            attachment: "任意",
                            rows: "6",
                            componentClass: "textarea",
                            bsClass: "form-control",
                            inputRef: register,
                            validationMessage: errors?.coupon?.body?.message,
                            showChangeIndicator: editMode && Boolean(dirtyFields.coupon?.body)
                          },
                          {
                            name: "coupon.terms",
                            label: "利用条件",
                            ncol: "col-md-12",
                            rows: "6",
                            componentClass: "textarea",
                            bsClass: "form-control",
                            inputRef: register({
                              required: "利用条件を入力してください。",
                            }),
                            validationMessage: errors?.coupon?.terms?.message,
                            showChangeIndicator: editMode && Boolean(dirtyFields.coupon?.terms)
                          },
                          {
                            name: "coupon.important_notes",
                            label: "利用時の注意事項",
                            ncol: "col-md-12",
                            attachment: "任意",
                            rows: "6",
                            componentClass: "textarea",
                            bsClass: "form-control",
                            inputRef: register,
                            showChangeIndicator: editMode && Boolean(dirtyFields.coupon?.important_notes)
                          },
                        ]}
                      />

                      <FormGroup bsSize="large">
                        <Controller
                          control={control}
                          name="coupon.image_url"
                          render={({ value }) => (
                            <ImageForm
                              doTrimming={false}
                              label="画像"
                              value={value}
                              onChange={(img) => {
                                setValue("coupon.image_url", img, { shouldDirty: true });
                              }}
                              validationMessage={errors?.coupon?.image_url?.message}
                              showChangeIndicator={editMode && Boolean(dirtyFields.coupon?.image_url)}
                            />
                          )}
                        />
                        <HelpBlock className="text-muted">
                          画像は縦横比16:9で表示されます。
                        </HelpBlock>
                      </FormGroup>

                      {/* 利用回数 */}
                      <Row>
                        <Col md={3} sm={3} xs={6}>
                          <FormGroup bsSize="large">
                            <SelectorForm
                              name="coupon.has_usage_limit"
                              inputRef={register}
                              label="利用回数"
                              options={[
                                {
                                  label: "1人1回まで",
                                  value: true,
                                },
                                {
                                  label: "何度でも可",
                                  value: false,
                                },
                              ]}
                              showChangeIndicator={editMode && (watchLimit !== coupon?.has_usage_limit)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* オプション */}
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <div style={{ display: "flex", alignItems: "flex-start" }}>
                              <FormLabel label="オプション" attachment="任意" />

                              {editMode && (isShowDiscountFormCard !== defaultShowDiscountFormCard) && (
                                <ChangeIndicator style={{ marginLeft: 8 }} />
                              )}
                            </div>
                            <Row>
                              <Col md={5}>
                                <Checkbox
                                  number="discount"
                                  inline
                                  label="割引設定"
                                  name="discount"
                                  disabled={coupon?.status === CouponStatus.Publish}
                                  checked={isShowDiscountFormCard}
                                  onChange={(e) => setIsShowDiscountFormCard(e.target.checked)}
                                  style={{marginBottom: 2}}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="clearfix" />
                    </>
                  }
                />
              </Col>
            </Row>

            {isShowDiscountFormCard && (
              <Row>
                <Col md={12}>
                  <DiscountFormCard
                    resourceName="coupon"
                    serviceTypes={ServiceTypes}
                    serviceTypeToJp={serviceTypeToJp}
                    register={register}
                    serviceType={watchServiceType}
                    errors={errors}
                    active={coupon?.status === CouponStatus.Publish}
                  />
                </Col>
              </Row>
            )}

            {coupon?.questionnaire_url && (
              <Row>
                <Col md={12}>
                  <QuestionnaireFormCard />
                </Col>
              </Row>
            )}

            <Row>
              <Col md={12}>
                <Card
                  title="配信対象者"
                  content={
                    <TargetUserForm
                      allLabel={watchCoupon.send_line_message ? "すべてのユーザー・LINE友だち" : null}
                      disabled={!isAuto && coupon?.status === CouponStatus.Publish}
                      errorMessage={errors?.target_user_condition?.["message"]}
                      targetUserCondition={watch("target_user_condition")}
                      onChangeTargetUserCondition={(targetUserCondition) => {
                        setValue("target_user_condition", targetUserCondition, { shouldDirty: true });
                      }}
                      showChangeIndicator={editMode && Boolean(dirtyFields.target_user_condition)}
                    />
                  }
                />
              </Col>
            </Row>

            {hasFunction("questionnaire") && (
              <Row>
                <Col md={12}>
                  <Card
                    title="アンケート回答クーポン"
                    content={
                      <CouponQuestionnaireForm
                        defaultQuestionnaireId={defaultQuestionnaireId}
                        questionnaireId={watchCoupon.questionnaire_id}
                        onChange={(questionnaireId) => {
                          setValue("coupon.questionnaire_id", questionnaireId, { shouldDirty: true });
                        }}
                      />
                    }
                  />
                </Col>
              </Row>
            )}

            <Row>
              <Col md={12}>
                {isAuto && (
                  <AutoPublishFormCard
                    name="coupon"
                    control={control}
                    register={register}
                    disabled={editMode}
                    categories={categories}
                    setValue={setValue}
                    errors={errors}
                    categoryToJp={categoryToJp}
                    category={watchCategory}
                    publishWeeks={watchPublishWeeks}
                    publishDates={watchPublishDates}
                    daysLater={watchDaysLater}
                    repeatType={repeatType}
                    setRepeatType={setRepeatType}
                    setError={setError}
                    clearErrors={clearErrors}
                  />
                )}
              </Col>
            </Row>

            {/* 作成 かつ クーポン */}
            {!editMode && !isAuto && (
              <PresentCouponDistributionSettingsCard
                editMode={editMode}
                coupon={coupon}
                onClickPresentCouponContentPublishButton={onClickPresentCouponContentPublishButton}
                statusToJp={statusToJp}
                statusLabelBackgroundColor={statusLabelBackgroundColor}
              />
            )}

            {/* 作成 かつ 自動配信クーポン */}
            {!editMode && isAuto && (
              <Row>
                <Col md={12}>
                  <AutoCouponFormActiveSettingCard
                    coupon={coupon}
                    onClickAutoCouponContentPublishButton={onClickAutoCouponContentPublishButton}
                    editMode={editMode}
                  />
                </Col>
              </Row>
            )}

            <Row>
              <Col md={12}>
                <Card
                  title="クーポン利用による売上設定"
                  category="売上効果の集計を設定金額 x 利用数で計算して表示します"
                  content={
                    <Row>
                      <FormInput
                        name="coupon.customer_unit_price"
                        unit="円"
                        ncol="col-md-3 col-sm-3 col-xs-8"
                        type="number"
                        bsClass="form-control"
                        inputRef={
                          register({
                            required: "クーポン利用による売上設定を入力してください。",
                            valueAsNumber: true,
                            min: {
                              value: 0,
                              message: "0 円以上の金額を設定してください。",
                            },
                          })
                        }
                        validationMessage={errors?.coupon?.customer_unit_price?.message}
                        showChangeIndicator={editMode && Boolean(dirtyFields.coupon?.customer_unit_price)}
                        style={{ minWidth: 100 }}
                      />
                    </Row>
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Card
                  title="目的・振り返り"
                  category="施策の振り返りや評価のためのメモを残すことができます。このメモはユーザーからは見えません。"
                  content={
                    <Row>
                      <FormInput
                        name="coupon.memo"
                        label="メモ"
                        attachment="任意"
                        placeholder="例：女性向けに配信、雨の日に配信など"
                        ncol="col-md-12"
                        rows="5"
                        componentClass="textarea"
                        bsClass="form-control"
                        inputRef={register}
                        validationMessage={errors?.coupon?.memo?.message}
                        showChangeIndicator={editMode && Boolean(dirtyFields.coupon?.memo)}
                      />
                    </Row>
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Row style={{ margin: "16px 0" }}>
                  <Col md={5} xs={6} mdOffset={1}>
                    <Button
                      color="cancel"
                      variant="contained"
                      fullWidth
                      disabled={api.loading}
                      onClick={history.goBack}
                    >
                      もどる
                    </Button>
                  </Col>
                  <Col md={5} xs={6}>
                    <Button
                      type="submit"
                      color="submit"
                      variant="contained"
                      fullWidth
                      disabled={!isDirty || isButtonLoading || api.loading}
                    >
                      {editMode ? "編集する" : "作成する"}
                    </Button>
                  </Col>
                </Row>

                {editMode && (
                  <Row>
                    <Col md={6} xs={8} mdOffset={3} xsOffset={2} style={{ textAlign: "center" }}>
                      <Button
                        color="danger"
                        onClick={handleClickDeleteButton}
                        disabled={api.loading}
                      >
                        このクーポンを削除する
                      </Button>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Form>

          {isPcOrTablet && (
            <div style={{ marginLeft: 16 }}>
              <CouponPreview couponContent={watchCoupon} />
            </div>
          )}
        </div>
      </Grid>

      <Prompt
        when={isDirty}
        message="行った変更が保存されていない可能性があります。このページを離れますか？"
      />
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isBackdropLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <FormChangedAlertModal
        show={isCouponDeleteModalOpen}
        title="クーポンを削除する"
        submitButtonLabel="削除する"
        danger
        disabled={api.loading}
        onSubmit={deleteCouponContent}
        needAgreement
        onCancel={closeCouponDeleteModal}
      >
        クーポンを削除してよろしいですか？
        <Alert severity="danger">
          <ul>
            <li>ユーザーに配信されたクーポンは削除されます。</li>
            <li>削除されたクーポンはトイポアプリから表示されなくなります。</li>
          </ul>
        </Alert>
      </FormChangedAlertModal>
    </FormProvider>
  );
};

const StyledCheckBoxContainer = styled("div")({
  backgroundColor: "#f0f1f4",
  padding: 10,
  display: "flex",
  alignItems: "center"
});

export default CouponContentForm;
